export const environment = {
  production: true,
  apiEndPoint: './wims-property-truth/public/api',
  imageEndPoint: './wims-property-truth/public/',
  googleMapsApiKey: 'AIzaSyBrlB81cnPdDjX25xmUdnDpwVdQ_ASC-fo',
  DATE_KEYS : ['start_date', 'end_date', 'invoice_date', 'due_date', 'date_of_entry', 'expiry_date', 'date','required_date', 'supplier_invoice_date', 'dob', 'cheque_date', 'proposed_date', 'date_of_request', 'date_of_install', 'warranty_date', 'key_hand_over_date', 'payment_due_date', 'startDate', 'endDate', 'bill_date', 'effective_date', 'joining_date', 'date_of_birth', 'pay_roll_date', 'completion_date', 'payment_date', 'requested_date', 'emi_date', 'effective_from', 'last_paid_date', 'scheduled_date','last_maintenance_date', 'next_maintenance_date', 'maintenance_date', 'Date', 'from_date', 'to_date','approved_date', 'starting_date', 'ending_date', 'delivery_date'],
  navigation: {
    defaultDashboard: '/properties/dashboard',
    lawyerDashboard: '/dashboard/user_dashboard'
  },
};
//defaultDashboard: '/properties/dashboard',

// export const environment = {
//   production: true,
//   apiEndPoint: '/api',
//   imageEndPoint: '/api/images',
//   googleMapsApiKey: 'AIzaSyBrlB81cnPdDjX25xmUdnDpwVdQ_ASC-fo'
// };


