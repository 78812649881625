import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class ExternalService {

    options: object;

    constructor(private http: HttpClient, public toastr: ToastrService, private datePipe: DatePipe) {

    }

    toExportFileName(excelFileName: string): string {
      return `${excelFileName}_export_${new Date().getTime()}.xlsx`;
    }

    SerachDeviceSerial(url) {
      return this.get(url);

    }


    public exportAsExcelFile(json: any[], excelFileName: string): void {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
      const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
      XLSX.writeFile(workbook, this.toExportFileName(excelFileName));
    }

    getContentsFromAmazonS3(config: { imageName: string, bucketName: string }) {

      // const signedUrlExpireSeconds = 60 * 1

      // const s3 = new AWS.S3({

      //   endpoint: "https://cncwims.s3.us-west-1.wasabisys.com/truth/documents/",


      //   signatureVersion: 'v4',

      //   region: 'us-west-1',

      //   secretAccessKey: 'cVfI24kAeqUUkbCyJEEPGePrcke2bidsg1aq8cZD',

      //   accessKeyId: '9HPAFAHWYZDOLLUU0K09'

      // });


      // const url = s3.getSignedUrl('getObject', {

      //   Bucket: config.bucketName,

      //   Key: config.imageName,

      //   Expires: signedUrlExpireSeconds

      // });

      // console.log(url, "amazon s3 url for accessing images");

      return 'url';
    }

    getAzureData() {
    //   let env = encodeURI('9HPAFAHWYZDOLLUU0K09/' + this.getDateAWS() +'/us-west-1/s3/aws4_request');
    //   let url = 'https://qtrs.s3.us-west-1.wasabisys.com/qtrs/Images/Products/DqN67P7D86.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential='+ env
    //   +'&X-Amz-Date='+this.getIsodate()+'&X-Amz-Expires=60&X-Amz-SignedHeaders=content-type%3Bhost&X-Amz-Signature='+this.getSignatureKey();
    //   console.log(url);
    //    let httpHeaders = {
    //     headers: new HttpHeaders({
    //       'x-amz-content-sha256': this.getSignatureKey(),
    //       'Authorization':  'AWS4-HMAC-SHA256 Credential=9HPAFAHWYZDOLLUU0K09/' + this.getDateAWS() +'/us-west-1/s3/aws4_request, SignedHeaders=host;x-amz-content-sha256;x-amz-date, Signature=cd7e4762aac71bdb690f925af486090e069302bc46db8039fe4ee8845177398c',
    //       'X-Amz-Date': this.getIsodate()
    //     })
    //   };
    //   return this.http.get('https://qtrs.s3.us-west-1.wasabisys.com/qtrs/Images/Products/DqN67P7D86.jpeg',httpHeaders);
    //
    // var opts = { host: 'qtrs.s3.us-west-1.wasabisys.com', path: '/DqN67P7D86.jpeg', service: 's3', region: 'us-west-1' }
    // aws4.sign(opts, { accessKeyId: '9HPAFAHWYZDOLLUU0K09', secretAccessKey: 'cVfI24kAeqUUkbCyJEEPGePrcke2bidsg1aq8cZD' });
    // console.log(opts);
    // var signOption = {
    //   url: 'https://us-west-1.hyper.sh/version',
    //   method: 'GET',
    //   credential: {
    //     accessKey: '6DPLADBPWYXDUVXLX34EJXBL',
    //     secretKey: '2ldD1Yz0nzATl9vvagBwYTjglXBjVOWU8gV8aMm5'
    //   }
    // }

    // var headers = aws4.sign(signOption)
  //   let requestOptions: any = {
  //     host: `${environment.Bucket}.s3.${environment.region}.amazonaws.com`,
  //     path: '/Images/Products/'
  // }

  // console.log(aws4);

  // aws4.sign(requestOptions, {
  //     secretAccessKey: environment.secretAccessKey,
  //     accessKeyId: environment.accessKeyId
  // })
  // console.log(requestOptions);

  // return requestOptions;
  }

    getIsodate() {
      let dd  = new Date().toISOString().split('.')[0];
      return dd.replace(/[-:.]/g,'') + 'Z';
    }
    getDateAWS() {
      return new Date().getFullYear().toString() + ("0" + (new Date().getMonth() + 1)).slice(-2).toString() + ("0" + new Date().getDate()).slice(-2).toString();
    }
    getSignInKey() {
      // let key = crypto.HmacSHA256(crypto.HmacSHA256(crypto.HmacSHA256(crypto.HmacSHA256(this.getIsodate(),"AWS4" +
      //  environment.secretAccessKey),environment.region),"s3"),"aws4_request");
      //  return key;
      let str = 'AWS4-HMAC-SHA256\n' +  this.getIsodate() + '\n' + this.getDateAWS() + '/us-west-1/s3/aws4_request' + '\n';
      //return crypto.HmacSHA256(str);
    }

    getSignatureKey() {
      // var kDate = crypto.HmacSHA256(this.getIsodate(), "AWS4" + environment.secretAccessKey);
      // var kRegion = crypto.HmacSHA256(environment.region, kDate);
      // var kService = crypto.HmacSHA256('s3', kRegion);
      // var kSigning = crypto.HmacSHA256("aws4_request", kService);
      // return kSigning;
  }


    getAll(url: string) {
      this.options = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization':  'Bearer '+localStorage.getItem('token')
        })
      };
      return this.http.get<any>(environment.apiEndPoint+url,this.options);
    }

    getPostDetails(reqBody) {

      return this.post(reqBody.api, reqBody.body)
    }

    postAll(url: string, resource) {
      this.options = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization':  'Bearer '+localStorage.getItem('token')
        })
      };
      return this.http.post<any>(environment.apiEndPoint+url, JSON.stringify(resource),this.options)
      .pipe(map(result => {
        return result;
      }));
  }

  doPost(url: string, resource: any): Observable<any> {
    this.options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post<any>(environment.apiEndPoint + url, resource, this.options);
  }
  doGet(url: string): Observable<any> {
    this.options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.get<any>(environment.apiEndPoint + url, this.options);
  }

  get(url: string): Observable<any> {
    this.options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    };

    return this.http.get<any>(environment.apiEndPoint + url, this.options);
  }
  post(url: string, resource: any): Observable<any> {
    this.options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    };

    const reqBody = this.getFormatedReqBody(resource)

    return this.http.post<any>(environment.apiEndPoint + url, reqBody, this.options);
  }


  getFormatedReqBody(resource) {
    const reqBody =  resource ? Object.keys(resource).map(key => {

      resource[key] = this.getDateKeys(key) ? this.datePipe.transform(new Date(resource[key]), 'yyyy-MM-dd') : resource[key]
         return {
           [key]: resource[key]
         }
    }) : []
    return Object.assign({}, ...reqBody)
  }


  getDateKeys(key) {
    return environment.DATE_KEYS.indexOf(key) > -1
  }

  validate(fieldName: string, fieldValue: string, modelName: string): Observable<boolean> {
    const params = new HttpParams()
      .set('field_name', fieldName)
      .set('field_value', fieldValue)
      .set('model_name', modelName);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      });
      return this.http.get<any>(`${environment.apiEndPoint}/validation`, { params,headers }).pipe( map(response => {
        // Assuming the API returns a property 'isValid' or similar
        // Adjust this according to your actual API response structure
        return response.isValid === true;
      // return !response.isValid;
      })
    );
  }
  create(url: string, resource): Observable<any> {
    this.options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post<any>(environment.apiEndPoint + url, JSON.stringify(resource), this.options)
      .pipe(map(result => {
          // this.toastr.success( 'Created Successfully' , 'Success');
           return result;
        }));
    }

    update(url: string,resource): Observable<any> {
      this.options = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization':  'Bearer '+localStorage.getItem('token')
        })
      };
      const reqBody = this.getFormatedReqBody(resource)

      return this.http.patch<any>(environment.apiEndPoint+url , JSON.stringify(reqBody),this.options)
      .pipe(map(result => {
           return result;
        }));
    }

    delete(url: string) {
      this.options = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization':  'Bearer '+localStorage.getItem('token')
        })
      };
      return this.http.delete<any>(environment.apiEndPoint+url,this.options)
      .pipe(map(result => {
         //  this.toastr.success( 'Deleted Successfully' , 'Success');
           return result;
        }));
    }
    file(url: string,formData : any) {
      this.options = {
        headers: new HttpHeaders({
          'Content-Type':  'multipart/form-data',
          'Authorization':  'Bearer '+localStorage.getItem('token')
        })
      };
      return this.http.post<any>(environment.apiEndPoint+url, formData,this.options)
      .pipe(map(result => {
        this.toastr.success('File Uploaded Successfully', 'File');
        return result;
      }));
  }

  fileUpload(url: string, formData: FormData) {
    const options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post<any>(environment.apiEndPoint + url, formData, options)
      .pipe(
        map(result => {
          return result;
        }),
      );
  }


  getPropertyInfo() {
    return this.http.get('./assets/json/property-details.json');
  }

  getUnitInfo() {
    return this.http.get('./assets/json/unit-details.json');
  }

  getAllAmenities() {
    return this.http.get('./assets/json/amenities.json');
  }

  getAllUnitTypes() {
    return this.http.get('./assets/json/unit-types.json');
  }

  downloadPDF(url): any {
    // const options = { responseType: Response.Blob  };
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'blob',
      }), responseType: 'blob'
    }).pipe(map(
      (res: any) => {
        return new Blob([res.data], { type: 'application/pdf' });
      }));
  }


  hasPermission(type) {
    let res = JSON.parse(localStorage.getItem('permissions')) ? JSON.parse(localStorage.getItem('permissions')) : [];
    return res.map((permission) => {

    });
  }

    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('currentUser');
    }
}
